h1 {
  display: flex;
  justify-content: center;
}

.table {
  &-header {
    display: flex;
    justify-content: space-between;
    padding: 10px 10px;
  }
  &-col {
    flex: 1;
    align-items: center;
  }

  &-row {
    display: flex;
    justify-content: space-evenly;
    padding: 10px 10px;

    &:nth-child(2n) {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
}

#details-map {
  height: 100%;
  width: 100%;
}

.details-map {
  height: 250px;
  position: relative;
  position: fixed;
  left: 0;
  right: 0;
  height: 45vh;
  top: 0;
  z-index: 2100;
  padding-bottom: 50px;
  background: linear-gradient(
    180deg,
    var(--background-color) 85%,
    var(--background-color-transparent)
  );

  &.big {
    height: 100vh;
  }
}

.content-wrapper {
  top: 45vh;
  position: relative;
}

.map-button {
  position: absolute;
  z-index: 2000;
  bottom: 4px;
  right: 5px;
  opacity: 0.8;
  padding: 5px;
  background-color: var(--background-color);
  border-radius: 5px;
}

.map-buttons {
  position: absolute;
  left: 15px;
  bottom: 15px;
  display: flex;
  width: 30px;
  display: flex;
  align-items: center;
  z-index: 1000;
  flex-direction: column;
  gap: 15px;

  @media (min-width: 768px) {
    margin-left: 150px;
  }
}

.header-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;

  &__fixed {
    position: fixed;
    width: 100%;
    z-index: 2200;
    background: linear-gradient(
      180deg,
      var(--background-color) 65%,
      var(--background-color-transparent)
    );
    opacity: 0.8;
    left: 0;
    right: 0;
    padding: 0 15px;
  }

  .header-text {
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    h3 {
      margin: 10px 0;
    }

    .header-subtitle {
      color: grey;
      font-size: 13px;
    }
  }
}

.container {
  height: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.p-absolute {
  position: absolute;
  transition: all 0.3s;
}

.locate-user {
  position: absolute;
  left: 5px;
  z-index: 1000;
  // background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 50px;
  margin: 10px;
  box-shadow: 0px 0px 17px var(--shadow-color);
  opacity: 0.9;
  bottom: 10px;
  transition: all 0.3s;

  @media (min-width: 768px) {
    margin-left: 150px;
  }

  @media (max-width: 374px) {
    margin-left: 5px;
  }

  @media (min-width: 1024px) {
    margin-left: 250px;
  }
}

.content-block {
  margin-top: 15px;
}

@keyframes fade {
  from {
    opacity: 0.5;
  }
}

.blinking {
  animation: fade 1s infinite alternate;
}

.fav-button {
  background-color: var(--background-color-semi-transparent);
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;

  box-shadow: 0 0 40px 0px var(--shadow-color);
  transition: all 0.5s;

  &.active {
    box-shadow: 0 0 10px 0px lightcoral;
    transform: scale(0.7);
    opacity: 0.7;
  }
}

.schedule-label {
  position: absolute;
  z-index: 1000;
  bottom: 65px;
  left: 5px;
  margin-left: 15px;
  width: 150px;
  font-size: 14px;
  color: #949494;

  @media (min-width: 768px) {
    margin-left: 150px;
  }

  @media (max-width: 374px) {
    margin-left: 5px;
  }

  @media (min-width: 1024px) {
    margin-left: 250px;
  }
}

.schedule-icon {
  position: absolute;
  z-index: 1000;
  width: 42px;
  height: 42px;
  bottom: 80px;
  left: 5px;
  margin-left: 15px;

  @media (min-width: 768px) {
    margin-left: 150px;
  }

  @media (max-width: 374px) {
    margin-left: 5px;
  }

  @media (min-width: 1024px) {
    margin-left: 250px;
  }

  .small {
    width: 25px;
    height: 25px;
  }
}

.schedule-wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .schedule-header-title {
    margin-top: 20px;
    display: flex;
    display: flex;
    background: #1a2e48;
    color: white;
    font-weight: 600;
    padding: 10px;
    justify-content: space-around;
    text-transform: uppercase;
    position: sticky;
    top: 40px;
  }

  .schedule-title {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .schedule-content {
    display: flex;
    justify-content: space-around;

    &__item {
      display: flex;
      flex-direction: column;
      padding-top: 10px;
      background-color: #d3d3d359;
      width: 50%;
      text-align: center;

      div {
        padding: 4px;
        border: 1px solid white;

        &.active {
          color: white;
          background-color: #9e2c2c;
          scroll-margin: 100px;
          font-weight: 600;
          font-size: 18px;
        }
      }
    }
  }
}

.sticky-header {
  position: sticky;
  top: 0;
  background-color: white;
}
