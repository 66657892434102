.messanger-input {
    position: fixed;
    bottom: 10px;
    left: 0;
    right: 0;
    padding: 5px 15px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    input {
        font-size: 16px;
        width: calc(100% - 70px);
        background-color: var(--shadow-color);
        color: var(--main-text-color);
        border: none;
        border-radius: 20px;
        height: 30px;
        margin: 1px;
        padding-left: 20px;

        &:focus {
            margin: 0;
            outline: none;
            border: 1px solid grey;
        }

    }
}
.send-button {
    fill: var(--main-text-color);
    transform: rotate(45deg);
}

.container {
    padding-bottom: 70px;
}