.messages-container {
    display: flex;
    flex-direction: column;
}
.message-bubble {
    display: flex;
    padding: 5px 15px;
    background-color: var(--shadow-color);
    border-radius: 40px;
    max-width: 500px;
    align-self: start;
    color: var(--main-text-color);

    &.response {
        align-self: flex-end;
        color: white;
        background-color: #99bace;
    }
}