@import './variables';

.modal {
    &-container {
        position: absolute;
        z-index: 2000;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: var(--background-color);
        border-radius: 30px 30px 0 0;
        padding: 0 15px 15px;
        transition: all .3s ease-in;
        top: 75%;
        box-shadow: 0 -10px 10px var(--shadow-color);
        overflow: hidden;
        margin: 0 10px;

        @media (min-width: 768px) {
            margin: 0 150px
        }

        @media (max-width: 374px) {
            margin: 0 5px
        }

        @media (min-width: 1024px) {
            margin: 0 250px
        }
        .drag-container {
            padding: 7px 15px 15px;
            flex: 1;
        }

        .drag-indicator {
            height: 3px;
            background-color: rgb(228, 183, 183);
            width: 60px;
            border-radius: 4px;
            flex: 1;
            margin: auto;
        }
    }

    &-expanded {
        top: 30px!important;

        .details-content {
            pointer-events: all;
        }

        .route-graph {
            .route-node-left {
                pointer-events: all!important;
            }
        }
    }
}

.pt-10 {
  padding-top: 10px!important;
}