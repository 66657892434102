.info-block {
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 14px;
    background-color: var(--info-background-color);
    text-align: center;
    color: var(--info-text-color);
    position: relative;
    align-self:stretch;
    z-index: 2000;

    &-close {
        position: absolute;
        right: 7px;
        background: inherit;
    }
}