.card-wrapper {
  display: flex;

  &-info {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &-subtext {
      font-size: 12px;
    }
  }

  .card {
    flex: 1;
    justify-content: space-between;
  }
}

.card {
  padding: 20px;
  background-color: #eae2df;
  border-radius: 5px;
  display: flex;

  &-tram {
    background-color: var(--tram-card-background);
  }
  &-trol {
    background-color: var(--trol-card-background);
  }
  &-bus {
    background-color: var(--bus-card-background);
  }

  &-icons-block {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  &-description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .subtitle {
      font-size: 13px;
    }
  }
}

.card-stack {
  position: relative;
  .background-card {
    width: 100%;
    right: 0;
    z-index: -1;
    top: 0px;
    transition: transform 100ms linear;
  }
}

.visibility-h {
  visibility: hidden;
}

.mt-15 {
  margin-top: 15px;
}

.mt-25 {
  margin-top: 25px;
}

.mb-15 {
  margin-bottom: 15px;
}

.p-absolute {
  position: absolute;
}

.d-flex {
  display: flex;
}

.f-column {
  display: flex;
  flex-direction: column;
}
