.tabs {
  text-align: center;
  &__title {
    display: flex;
    flex: 1;
    justify-content: space-between;
  }
  .tab {
    display: inline-block;
    padding: 10px;
    cursor: pointer;
    color: var(--secondary-text-color);

    &:hover,
    &.active {
      border-bottom: 2px solid #bbcde0;
      color: var(--secondary-text-color-active);
      font-weight: 500;
    }
  }
}
.content {
  padding-top: 10px;
  color: var(--main-text-color);
  background:var(--background-color);
}
