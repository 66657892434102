@import 'styles/detailedPage.scss';

.container {
  padding: 20px 20px;

  @media (min-width: 1025px) {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
    width: 1170px;
  }
}

.App {
  text-align: center;
}

.light-theme {
  --primary-color: #007bff;
  --disabled-color: #b5b9b9;

  --background-color: #ffff;
  --background-color-semi-transparent: rgba(256, 256, 256, 0.8);
  --background-color-transparent: rgba(256, 256, 256, 0);

  --shadow-color: #c2c5c573;
  --main-text-color: #464646;
  --secondary-text-color: #bbcde0;
  --secondary-text-color-active: #6385a9;

  --info-background-color: #dff5ff;
  --info-text-color: #0271b5;
  --primary-button-background: #007bff;

  --bus-card-background: #d2e2e2;
  --trol-card-background: #e1eadf;
  --tram-card-background: #ecd3c9;
}

.dark-theme {
  --primary-color: #000000;
  --disabled-color: #4a4a4b;

  --shadow-color: #00b5ff45;

  --info-background-color: #0e465d;
  --info-text-color: var(--secondary-text-color);

  --background-color: #000000;
  --background-color-semi-transparent: rgba(0, 0, 0, 0.8);
  --background-color-transparent: rgba(0, 0, 0, 0);

  --main-text-color: #ffff;
  --secondary-text-color: #7bc7ec;
  --secondary-text-color-active: #ffff;
  --primary-button-background: #007bff;

  --bus-card-background: #23304c;
  --trol-card-background: #12270e;
  --tram-card-background: #351c19;
}

.leaflet-control-attribution {
  display: none;
}

.theme-switcher {
  z-index: 1000;
  position: absolute;
  font-size: 20px;
  border-radius: 50%;
  left: 10px;
  color: black;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 180px;
  font-weight: 600;
  font-style: italic;
  opacity: 0.8;
  margin-left: 10px;
}

body {
  position: relative;
  color: var(--main-text-color);
  font-family: Arial;
  background-color: var(--background-color);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.icon {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;

  &-accessability {
    width: 24px;
    height: 24px;
    background: url('./icons/accessability.png') no-repeat center center;
  }
}

.circle {
  border-radius: 100%;
  display: inline-flex;
  width: 40px;
  height: 35px;
  justify-content: center;
  align-items: center;
}

.badge-filled {
  background: black;
  color: white;
  font-weight: 600;
  padding: 2px 7px;
  font-size: 14px;
  border-radius: 14px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.page-back-button {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1000;
  background-color: var(--shadow-color);
  border-radius: 11px;
  padding: 8px 15px;
  box-shadow: 0 0 20px var(--shadow-color);
  font-size: 20px;
  font-weight: 900;
  display: flex;
  align-items: baseline;
}

.m-right-15 {
  margin-right: 15px;
}

.m-top-15 {
  margin-top: 15px;
}

@for $i from 1 through 5 {
  .mb-#{$i} {
    margin-bottom: #{$i * 10}px !important;
  }
}

@for $i from 1 through 5 {
  .mr-#{$i} {
    margin-right: #{$i * 10}px !important;
  }
}

.mt-10 {
  margin-top: 10px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.danger {
  color: red;
  font-weight: 600;
}

.absolute-bottom {
  position: absolute;
  bottom: 0;
  z-index: 1000;
  margin-bottom: 70px;
  right: 20px;
  left: 70px;
  display: flex;
  padding: 8px;
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: var(--main-text-color);
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &.btn-primary {
    color: #fff;
    background-color: var(--primary-button-background);
    border-color: var(--primary-button-background);

    &:disabled {
      background-color: var(--disabled-color);
      border-color: var(--shadow-color);
    }
  }

  &.btn-outlined {
    border: 1px solid var(--shadow-color);
    color: var(--main-text-color);
    font-size: 13px;
    line-height: 13px;
  }
}

.leaflet-control-zoom {
  background-color: var(--background-color);

  a {
    background-color: var(--background-color);
    border: var(--shadow-color);
    color: var(--main-text-color);

    &:hover {
      background-color: var(--shadow-color);
      border: var(--shadow-color);
      color: var(--main-text-color);
    }

    &.leaflet-disabled {
      background-color: var(--shadow-color);
    }
  }
}

.top-absolute-full-width {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.fixed-full-screen {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.bus-stop-marker {
  z-index: 1100 !important;
  .marker-inner {
    width: 38px;
    height: 95px;
    display: block;
    background: url('/bus-stop.png') no-repeat center center;

    -webkit-animation-duration: 0.7s;
    animation-duration: 0.7s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;

    &.active {
      -webkit-animation-name: bounce;
      animation-name: bounce;
    }
  }

  @-webkit-keyframes bounce {
    0%,
    100% {
      -webkit-transform: translateY(0);
    }
    50% {
      -webkit-transform: translateY(-15px);
    }
  }
  @keyframes bounce {
    0%,
    100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-15px);
    }
  }
}

.info-btn,
.fav-icon {
  z-index: 1000;
  position: absolute;
  font-size: 20px;
  border-radius: 50%;
  background-color: var(--background-color-transparent);
  left: 10px;
  color: black;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 80px;
  box-shadow: 0px 0px 20px var(--shadow-color);
  font-weight: 600;
  font-style: italic;
  opacity: 0.6;
  margin-left: 10px;
  color: var(--main-text-color);

  @media (min-width: 768px) {
    margin-left: 150px;
  }

  @media (max-width: 374px) {
    margin-left: 5px;
  }

  @media (min-width: 1024px) {
    margin-left: 250px;
  }
}
.fav-icon {
  opacity: 0.9;
}

.text-area {
  border: 1px solid lightgrey;
  border-radius: 4px;
  font-size: 1rem;
}

.visibility-h {
  visibility: hidden;
}

.secondaryText {
  font-size: 0.8rem;
  color: grey;
}

.text-center {
  text-align: center;
}

.flex-center {
  display: flex;
  justify-self: center;
  align-self: center;
}

img {
  max-width: 150px;
  width: auto;
  height: auto;
}
