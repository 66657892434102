.global-message {
  display: flex;
  position: fixed;
  top: 10px;
  left: 110vw;
  transition: all 1s ease-in-out;
  width: calc(100% - 50px);
  z-index: 2100;
  padding: 15px;
  border-radius: 4px;
  background-color: #9098b5cc;
  color: white;
  box-shadow: 0 0 10px 4px var(--shadow-color);
  align-items: center;

  @media (min-width: 768px) {
    width: calc(60% - 50px);
  }

  @media (min-width: 1441px) {
    width: calc(20% - 50px);
  }
  @media (min-width: 1024px) {
    width: calc(40% - 50px);
  }

  .global-message-close {
    position: absolute;
    right: 10px;
    font-weight: bold;
    font-size: 25px;
  }

  &.global-message__show {
    left: 10px;
  }

  &.global-message__hide {
    left: -1000%;
  }
}
