.input-wrapper {
  display: flex;
  flex-direction: column;

  .input-name {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .input-container {
    display: flex;
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    margin-bottom: 0;
    line-height: 1.5;
    color: var(--main-text-color);
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0;
    background-color: var(--background-color);
    border-radius: 4px;

    input {
      line-height: 1.5;
      color: var(--main-text-color);
      background-color: transparent;
      border: solid transparent;
      border-width: 1px 0;
      font-size: 1rem;
      padding: 0.375rem 0.75rem;
      width: 100%;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border: 1px solid var(--shadow-color);
      border-right: none;

      &:focus {
        outline: 1px auto var(--shadow-color);;
      }
    }
    button {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  }
}

.input-with-select {
  display: flex;
  align-items: center;

  input {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }
}

/* Reset Select */
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;

  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 1px solid var(--shadow-color);;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  border-right: none;
  padding: 5px 10px;
  background-image: none;
}
/* Remove IE arrow */
select::-ms-expand {
  display: none;
}
/* Custom Select */
.select {
  background-color: var(--background-color);
  border: thin solid var(--shadow-color);
  line-height: 1.2em !important;
  border-right: none;
  display: inline-block;
  font: inherit;
  padding: 0.5em 2em 0.6em 1em;
  color: var(--main-text-color);

  &:focus {
    outline: 1px auto #aac7e6;
  }

  /* reset */

  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}
select.minimal {
  background-image: linear-gradient(45deg, transparent 50%, #6385a9 50%),
    linear-gradient(135deg, #6385a9 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em), calc(100% - 15px) calc(1em),
    calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
}
