@import './variables';

.route-details-map {
  width: 100%;
  height: 100vh;
}

.details-header {
  display: flex;
  padding-bottom: 20px;

  svg {
    width: 32px;
    max-height: 32px;
  }
}

.details-content {
  // pointer-events: none;
}

.details-title {
  color: $green;
  font-size: 35px;
  font-weight: 900;
  line-height: 35px;

  &.back {
    color: $colorBlue;
  }
}

.details-subtitle-block {
  display: flex;
  flex-direction: column;

  .details-subtitle-small {
    font-size: 14px;
    color: #949494;
  }
}

.graphs-content {
  display: flex;

  &.column {
    flex-direction: column;
  }
}

.modal-expanded {
  .graphs-content {
    overflow: scroll;
    height: calc(100vh - 120px);
  }
}

.route-graph {
  .route-node {
    display: flex;
    position: relative;
    min-height: 50px;
    justify-content: space-between;

    .route-node-right {
      white-space: nowrap;
    }

    .route-node-left {
      display: flex;
      pointer-events: none;
    }

    .time-left {
      font-size: 14px;
      color: #737373;
    }

    .route-node-name {
      padding: 10px 0;
    }

    .route-node-indicator {
      border: 3px solid $green;
      display: flex;
      width: 10px;
      height: 10px;
      border-radius: 10px;
      margin-right: 20px;
      background-color: var(--background-color);

      &.back {
        border-color: $colorBlue;
      }
    }

    .route-node-connector {
      display: block;
      position: absolute;
      width: 3px;
      background: $green;
      height: 100%;
      left: 6px;
      z-index: -1;

      &.back {
        background: $colorBlue;
      }
    }
  }
}
.bus-icon-custom {
  width: 30px;
  height: 10px;
  z-index: 100;
  background-color: #5672ff;
}

.bus-stop-node {
  border: 3px solid #ffff;
  border-radius: 50px;
  background-color: var(--background-color);

  span {
    position: absolute;
    left: 19px;
    bottom: -4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    // max-width: 80px;
    background: rgba(256, 256, 256, 0.7);
    border-radius: 4px;
    padding: 0 5px;
  }

  &.there {
    color: $green;
    border-color: $green;
    transform: translateX(-130%);

    span {
      bottom: -4px;
      left: -87px;
      text-align: right;
    }
  }

  &.back {
    color: $colorBlue;
    border-color: $colorBlue;
  }
}

.shown-zoom-mt-16,
.shown-zoom-mt-14 {
  display: none;
}

.zoom-value {
  &-16,
  &-17,
  &-18,
  &-19,
  &-20 {
    .shown-zoom-mt-16 {
      display: block;
    }
  }

  &-16,
  &-17,
  &-18,
  &-19,
  &-20 {
    .shown-zoom-mt-16 {
      display: block;
    }
  }

  &-14,
  &-15,
  &-16,
  &-17,
  &-18,
  &-19,
  &-20 {
    .shown-zoom-mt-14 {
      display: block;
    }
  }
}

.bus-marker {
  z-index: 1000 !important;
  .marker-bus-inner {
    border-radius: 50%;
    background-color: $green;
    position: relative;
    color: var(--background-color);
    font-size: 16px;
    text-align: center;
    line-height: 25px;
    border: 1px solid var(--background-color);
  }

  .label {
    position: relative;
    background-color: var(--background-color);
    padding: 3px;
    box-shadow: 0 0 3px var(--shadow-color);
    font-weight: 500;
    color: var(--main-text-color);
    opacity: 0.7;
  }

  &.there {
    background-color: $colorBlue;
  }
}

.transfer-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.route-node-transfer {
  display: inline-block;
  background-color: grey;
  width: 18px;
  height: 18px;
  color: var(--main-text-color);
  font-weight: 600;
  border-radius: 50%;
  font-size: 11px;
  padding: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 3px;
}
